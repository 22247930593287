import { start } from '..'

const NOOP_PROMISE = () => Promise.resolve()

function onErrorDefault(err) {
  console.error('An error occurred during startup:', err)
  throw err
  // Display an error message to the user or log the error to an external service
}

function onLoad() {
  const loadingIndicator = document.getElementById(
    'bridge-platform-loading-indicator'
  )
  if (loadingIndicator) {
    loadingIndicator.remove()
  }
  const bridgePlatform = document.getElementById('bridge-platform')
  if (bridgePlatform) {
    bridgePlatform.classList.remove('bridge-platform-apps--loading')
  }
}

function onStart({ enabledApplications, initialApplications }) {
  const errorLog = document.getElementById('boot_error_log')
  if (errorLog) {
    errorLog.remove()
  }
  console.log('Platform (enabled) applications:', enabledApplications)
  console.log('Platform (active) applications:', initialApplications)
}

export function startApp({
  beforeStart = NOOP_PROMISE,
  onError = onErrorDefault,
  services = {},
} = {}) {
  return beforeStart()
    .then((onInit) =>
      start({
        rootDOMNode: document.getElementById('bridge-platform'),
        onInit,
        onLoad,
        services,
      })
    )
    .then(onStart)
    .catch(onError)
}
